import React from 'react';
import './App.css';
import logo from './infisms_logo.png'; // Tell webpack this JS file uses this image
import { ConnectButton } from "360dialog-connect-button";

const App = () => {
  const handleCallback = (callbackObject) => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */
    console.log("client ID: "+callbackObject.client)
    console.log("channel IDs: " + callbackObject.channels);
  }
  return (
    <div className="App">
      <img src={logo} height="90" width={200}/>
      <div>
        <p style={{ fontWeight: 'bold' }}>This is Sign up Button for OBA</p>
    </div>  
      <ConnectButton label="Connect InfiSMS" 
      style={{background: "#8A307F", 
      color: "#fff", outline: "none", 
      border: "none", 
      padding: "16px 18px", 
      borderRadius: 10}}
      partnerId={'2SmENbPA'}
      callback={handleCallback}
      />
    </div>
  );
};
export default App;



